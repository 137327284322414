.contact-form-container {

    .contact-form {

        width: 100%;
        height: 100%;

        ul {
            padding: 0;
            margin: 0;

            li {
                padding: 0;
                margin: 0;
                list-style: none;
                margin-bottom: 10px;
                overflow: hidden;
                display: block;
                position: relative;
                clear: both;
                box-sizing: border-box;
                border-radius: 2px;

            }

            li.half {
                width: 49%;
                margin-left: 2%;
                float: left;
                clear: none;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        input[type='text'],
        input[type='email'] {
            width: 100%;
            border: 0;
            background: #134772a4;
            height: 50px;
            font-size: 16px;
            color: #FFFFFF;
            padding: 0 20px;
            box-sizing: border-box;
        }

        textarea {
            width: 100%;
            border: 0;
            background: #134772a4;
            height: 50px;
            font-size: 16px;
            padding: 20px;
            color: #FFFFFF;
            min-height: 150px;
            box-sizing: border-box;
        }

        .flat-button {
            color: rgb(255, 255, 255);
            background: #134772a4;
            box-sizing: border-box;
            border: 1px solid #FFFFFF;
            border-radius: 2px;
            font: 11px;
            letter-spacing: 3px;
            text-decoration: none;
            padding: 8px 10px;
            float: left;
            cursor: pointer;
        }

        .flat-button::after {
            color: #000000;
        }

    }



}