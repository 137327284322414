.nav-bar {
    background: #080e2b;
    width: 100px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
    min-height: 500px;
    transition: width 0.3s ease, left 0.3s ease;

    .logo {
        display: block;
        flex-direction: column;

        img {
            margin: auto;
            margin-top: 30%;
            width: 75%;
            display: block;
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 90%;
        width: 100%;

        a {
            font-size: 15px;
            color: #FFFFFF;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #5B8FB9;

                svg {
                    opacity: 0;
                }

                &::after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease;

            }


        }

        a.home-link {
            top: 30%;

            &:after {
                content: 'HOME';
                font-size: small;
            }
        }

        a.about-link {
            top: 30%;

            &:after {
                content: 'PROJECTS';
                font-size: small;
            }
        }

        a.contact-link {
            top: 30%;

            &:after {
                content: 'CONTACT';
                font-size: small;
            }
        }

    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {

            a {
                color: #FFFFFF;
                padding: 7px 0;
                display: block;
                font-size: 17px;

                &:hover {
                    color: #5B8FB9;
                }

            }
        }
    }
}

.toggle-btn {
    cursor: pointer;
    position: fixed;
    z-index: 1000;
    display: none;

    .toggle-icon {
        position: absolute;
        width: 25px;
        height: auto;
        color: #FFFFFF;
    }

    .close-icon {
        position: absolute;
        width: 25px;
        height: auto;
        color: #FFFFFF;
    }
}

.dark-overlay.collapsed {
    display: none;
}

@media (max-width: 768px) {
    .nav-bar.collapsed {
        display: none;
    }

    .toggle-btn {
        display: block;
        left: 5%;
        top: 2.5%;

        .toggle-icon {
            display: block;
        }

        .close-icon {
            display: none;
        }

        transition: width 0.3s ease,
        left 0.3s ease;
    }

    .toggle-btn.collapsed {
        left: 120px;

        .toggle-icon {
            display: none;
        }

        .close-icon {
            display: block;
        }

        transition: width 0.3s ease,
        left 0.3s ease;
    }


    .dark-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        overflow: auto;
    }
}