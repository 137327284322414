@media (min-width: 769px) {

    .contact-page-container {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        .contact-page-logo-container {
            width: 30%;
            right: 10%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .contact-page {

            position: absolute;
            width: 30%;
            left: 15%;

            .text-zone {
                color: #FFFFFF;
                font-size: medium;
                width: 100%;

                h1 {
                    font-size: x-large;
                    width: 100%;
                    font-weight: normal;
                }

                p {
                    font-size: medium;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .contact-page-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;


        .contact-page-logo-container {
            position: absolute;
            width: 75%;
            top: 65%;
        }

        .contact-page {

            position: absolute;
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 80%;
            top: 5%;

            .text-zone {
                color: #FFFFFF;
                font-size: medium;
                width: 100%;
                text-align: center;

                h1 {
                    font-size: large;
                    font-weight: normal;
                    width: 100%;
                }

                p {
                    font-size: small;
                    width: 100%;
                }
            }
        }
    }
}