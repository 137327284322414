.desc-container {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    width: 30%;
    left: 15%;

    h1 {
        font-weight: normal;
        font-size: xx-large;
        color: #ffffff;
        width: 100%;
    }

    h2 {
        font-weight: normal;
        font-size: large;
        color: #ffffff;
        max-width: 100%;
    }


    p {
        font-size: 14px; 
    }

    @keyframes cursor {
        50% {
            border-color: transparent
        }
    }

}


@media (min-width: 768px) {

    .about-container {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        .desc-container {
            position: absolute;
            display: flex;
            align-items: center;
            height: 100%;
        
            h1 {
                font-weight: normal;
                font-size: x-large;
                color: #ffffff;
                width: 100%;
            }
        
            h2 {
                font-weight: normal;
                font-size: medium;
                color: #ffffff;
                max-width: 100%;
            }
            p {
                font-size: 14px; 
            }
            @keyframes cursor {
                50% {
                    border-color: transparent
                }
            }
        
        }
    }

        
    .about-logo-container {
        width: 30%;
        right: 10%; 
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    

}

@media (max-width: 768px) {

    .about-container {
        position: absolute;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .desc-container {
            height: 100%;
            width: 75%;
            left: calc(75% / 5.5);
            top: -15%;
            h2 {
                font-weight: normal;
                font-size: medium;
                color: #ffffff;
                text-align: center;
            }

            h1 {
                font-weight: normal;
                font-size: large;
                color: #ffffff;
                text-align: center;
            }
            p {
                font-size: 13px; 
            }
        }
    }

        
    .about-logo-container {
        width: 75%;
        top: 55%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    

}