.contact-page-logo-container {

    position: absolute;
    border-radius: 50%;

    .animated-logo-svg {

        position: absolute;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;


        .contact-svg-inner-container path {
            stroke: 10;
            stroke-width: 20;
            stroke: #FFFFFF;
            fill: transparent;
            stroke-dasharray: 25000;
            stroke-dashoffset: 25000;
            animation: animate 15s forwards;
        }
    }

    .contact-solid-logo {
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        opacity: 0;
        margin: auto;
        width: 100%;
        animation: fadeIn 3s forwards;
        animation-delay: 10s;
        border-radius: 50%;
    }

    @keyframes animate {
        to {
            stroke-dashoffset: 0;
            fill: #303644;
            stroke-opacity: 0;
        }
    }

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
}