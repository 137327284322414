@media screen and (min-width: 769px) {
    .welcome {

        position: absolute;
        display: flex;
        width: 50%;
        left: 15%;
        top: 10%;

        .projects-welcome-title {
            font-weight: normal;
            font-size: x-large;
            color: #ffffff;
            width: 100%;
        }

        .projects-welcome-text {
            font-weight: normal;
            font-size: medium;
            color: #ffffff;
            max-width: 100%;
        }
    
    }

    .project-links {


        position: absolute;
        display: flex;
        text-align: center;
        align-items: center;
        width: 50%;
        top: 35%;
        left: 15%;


        &.disable {

            .project-link,
            .project-link-in-development {

                opacity: 1;
                animation: none;
                padding-bottom: 50px;
                text-decoration: none;
                margin-left: 5%;

                .project-image {
                    width: 200px;
                    border-radius: 50%;
                }

                // project image that is under development
                .project-image-dev {
                    width: 200px;
                    cursor: default;
                    border-radius: 50%;
                }

                .project-image-text {
                    color: #ffffff;
                    display: block;
                    font-size: small;
                    margin-top: 2.5%;
                    cursor: default;
                }

                .library-logos {
                    justify-content: center;
                    width: 100%;
                    margin-top: 2.5%;
                }

                .library-logo {
                    width: auto;
                    margin-left: 10px;
                    height: 30px;
                }

                &:nth-child(1) {
                    animation-delay: 1.75s;
                }

                &:nth-child(2) {
                    animation-delay: 2.25s;
                }

                &:nth-child(3) {
                    animation-delay: 2.75s;
                }

                @keyframes fadeInRight {
                    to {
                        opacity: 1;
                    }
                }


            }
        }

        .project-link,
        .project-link-in-development {

            opacity: 0;
            animation: fadeInRight 0.5s ease-out forwards;
            padding-bottom: 50px;
            text-decoration: none;
            margin-left: 5%;

            .project-image {
                width: 200px;
                border-radius: 50%;
            }

            // project image that is under development
            .project-image-dev {
                width: 200px;
                cursor: default;
                border-radius: 50%;
            }

            .project-image-text {
                color: #ffffff;
                display: block;
                font-size: small;
                margin-top: 2.5%;
                cursor: default;
            }

            .library-logos {
                justify-content: center;
                width: 100%;
                margin-top: 2.5%;
            }

            .library-logo {
                width: auto;
                margin-left: 10px;
                height: 30px;
            }

            &:nth-child(1) {
                animation-delay: 1.75s;
            }

            &:nth-child(2) {
                animation-delay: 2.25s;
            }

            &:nth-child(3) {
                animation-delay: 2.75s;
            }

            @keyframes fadeInRight {
                to {
                    opacity: 1;
                }
            }


        }


    }

    .project-image {
        opacity: 0.7;
        transition: opacity 0.25s ease;
    }


}


.project-image:hover {
    opacity: 1;
}


@media screen and (max-width: 768px) {

    .projects-container {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .welcome {

        position: absolute;
        display: flex;
        text-align: center;
        justify-content: center;
        top: 15%;
        width: 100%;

        .projects-welcome-title {
            color: #ffffff;
            font-weight: normal;
            font-size: large;
            width: 80%;
        }

        .projects-welcome-text {
            color: #ffffff;
            font-weight: normal;
            font-size: small;
            width: 100%;
        }
    }

    .project-links {

        position: absolute;
        text-align: center;
        justify-content: center;
        top: 40%;
        width: 100%;
        display: grid;

        .project-link,
        .project-link-in-development {

            opacity: 0;
            animation: fadeInRight 0.5s ease-out forwards;
            padding-bottom: 50px;
            text-decoration: none;

            .project-image {
                width: 200px;
                border-radius: 50%;
            }

            // project image that is under development
            .project-image-dev {
                width: 200px;
                cursor: default;
                border-radius: 50%;
            }

            .project-image-text {
                color: #ffffff;
                display: block;
                font-size: small;
                margin-top: 2.5%;
                cursor: default;
            }

            .library-logos {
                justify-content: center;
                width: 100%;
                margin-top: 2.5%;
            }

            .library-logo {
                width: auto;
                margin-left: 10px;
                height: 30px;
            }

            &:nth-child(1) {
                animation-delay: 1.75s;
            }

            &:nth-child(2) {
                animation-delay: 2.25s;
            }

            &:nth-child(3) {
                animation-delay: 2.75s;
            }

            @keyframes fadeInRight {
                to {
                    opacity: 1;
                }
            }


        }

        &.disable {
            .project-link,
        .project-link-in-development {

            opacity: 1;
            animation: none;
            padding-bottom: 50px;
            text-decoration: none;

            .project-image {
                width: 200px;
                border-radius: 50%;
            }

            // project image that is under development
            .project-image-dev {
                width: 200px;
                cursor: default;
                border-radius: 50%;
            }

            .project-image-text {
                color: #ffffff;
                display: block;
                font-size: small;
                margin-top: 2.5%;
                cursor: default;
            }

            .library-logos {
                justify-content: center;
                width: 100%;
                margin-top: 2.5%;
            }

            .library-logo {
                width: auto;
                margin-left: 10px;
                height: 30px;
            }

            &:nth-child(1) {
                animation-delay: 1.75s;
            }

            &:nth-child(2) {
                animation-delay: 2.25s;
            }

            &:nth-child(3) {
                animation-delay: 2.75s;
            }

            @keyframes fadeInRight {
                to {
                    opacity: 1;
                }
            }


        }
        }


    }

}