.about-logo-container {
    margin-left: auto;
    margin-right: auto;

    .about-svg-outer-container {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
    }

    .about-svg-outer-container .about-svg-inner-container path {
        stroke: 10;
        stroke-width: 20;
        stroke: #FFFFFF;
        fill: transparent;
        stroke-dasharray: 25000;
        stroke-dashoffset: 25000;
        animation: animate 15s forwards;
    }

    

    .about-solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 0;
        animation: fadeIn 3s forwards;
        animation-delay: 10s;
    }

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    @keyframes animate
    {
        to {
            stroke-dashoffset: 0;
            fill: #303644;
            stroke-opacity: 0;
        }
      
    }
}

