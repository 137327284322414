@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

input,
textarea {
  font-family: 'Roboto', sans-serif;
}

body {
  background: black;

  .star {
    position: absolute;
    width: 3px;
    height: 3px;
    background: rgba(255,255,255,0.0);
    border-radius: 5px;
  }
  
  @keyframes twinkle {
    0% {
      transform: scale(1, 1);
      background: rgba(255,255,255,0.0);
      animation-timing-function: ease-in;
    }
    60% {
      transform: scale(0.8, 0.8);
      background: rgba(255,255,255,1);
      animation-timing-function: ease-out;
    }
    80% {
      background: rgba(255,255,255,0.00);
      transform: scale(1, 1);
    }
    100% {
      background: rgba(255,255,255,0.0);
      transform: scale(1, 1);
    }
  }
}

